import React, { useState, useEffect } from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { isBrowser } from "../../services/core/browser"
import Star from "../../resources/img/ui/icon-star.svg"
import id from "../../services/tracking/id"
import { getSetLink } from "../../services/product/link"
import AddToCartWizard from "../PDP/AddToCartWizard"
import { cvgTrack, parseProduct } from "../../services/tracking/converge"

const RecommendationContainer = styled.div`
  padding: 80px 15px 50px;
  text-align: center;
  position: relative;

  @media (min-width: ${breakpoints.sm}) {
    padding: 80px 60px 20px;
  }
  @media (min-width: ${breakpoints.md}) {
    padding: 100px 60px 20px;
  }
`

const Title = styled.h1`
  ${fonts.garamondRegular};
  font-size: 32px;
  max-width: 600px;
  line-height: 1.15;
  margin: 0 auto;

  @media (min-width: ${breakpoints.md}) {
    font-size: 40px;
    letter-spacing: -0.4px;
  }
`

const RecommendedProduct = styled.div`
  margin-top: 30px;
`

const ProductTitle = styled.h2`
  ${fonts.garamondRegular};
  color: ${colors.charcoal};
  font-size: 26px;
  line-height: 1.15;
  margin: 10px auto;

  a {
    color: inherit;
    text-decoration: none;
  }

  @media (min-width: ${breakpoints.md}) {
    font-size: 32px;
    letter-spacing: -0.4px;
  }
`

const ProductDetail = styled.div`
  ${fonts.sofiaPro};
  color: ${colors.charcoal};
  font-size: 18px;
  line-height: 1.38;
  max-width: 500px;
  margin: auto;
  @media (min-width: ${breakpoints.md}) {
    font-size: 14px;
  }
`

const ProductImage = styled(GatsbyImage)`
  max-width: 350px;
  height: 230px;
  img {
    object-fit: contain !important;
  }
`

const Price = styled.div`
  ${fonts.garamondSemiBold};
  color: ${colors.charcoal};
  font-size: 18px;
  line-height: 1.07;
  margin: 20px auto;
  @media (min-width: ${breakpoints.md}) {
    font-size: 18px;
    margin: 15px auto 20px;
  }
`

const ButtonContainer = styled.div`
  @media (min-width: ${breakpoints.sm}) {
    div {
      max-width: 400px;
    }
  }
`

const Anim = styled.div`
  width: 100%;
  padding-right: 30px;
  position: absolute;
  bottom: 40px;
  left: 0;
  pointer-events: none;

  @media (min-width: ${breakpoints.sm}) {
    padding-right: 60px;
  }
  @media (min-width: ${breakpoints.md}) {
    bottom: 0;
  }
`

const Rating = styled.div`
  ${fonts.sofiaPro};
  font-size: 16px;
  line-height: 1.33;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 600px;
  margin: 10px auto;
  img {
    margin-right: 4px;
  }
  span {
    margin-left: 3px;
  }
`
const WizardWrapper = styled.div`
  h3 {
    display: none;
  }
  @media (min-width: ${breakpoints.air}) {
    max-width: 40%;
    margin: auto;
  }
  margin-bottom: 30px !important;
`
const LearnMore = styled.a`
  text-decoration: underline;
  color: ${colors.charcoal};
  margin-top: 40px;
  font-weight: 800;
  text-underline-offset: 3px;
  text-decoration-thickness: 3px;
  transition: all 0.25s;
  :hover {
    color: ${colors.purple};
  }
`

const Recommendation = (props) => {
  const {
    suggestedSetCopy,
    recommendation,
    result,
    products: originalProducts,
    loading,
    reviews,
  } = props

  const {
    atcHeaderStep1,
    atcHeaderStep2,
    atcAutoRenewSubcopy,
    atcOneTimePurchaseSubcopy,
    atcAutoRenewSubcopyStep2
  } = recommendation

  const compareFn = (a, b) => {
    if (a.title.includes("Conditioner") && b.title.includes("Shampoo")) {
      return 1
    }
    if (a.title.includes("Serum") && (b.title.includes("Shampoo") || b.title.includes("Conditioner"))) {
      return 1
    }
    if (a.title.includes("Mask") && !b.title.includes("Brush")) {
      return 1
    }
    if (a.title.includes("Brush")) {
      return 1
    }
    return -1
  }

  const sortedOriginalProducts = originalProducts.sort(compareFn)

  const [popupOpen, setPopupOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(loading)
  const [products, setProducts] = useState(originalProducts)

  // console.log(originalProducts)

  let dynamicCopy = suggestedSetCopy.replace(" [NAME]", "")
  let productTitle
  let productImages
  let productDetail
  let productPrice
  let productLink

  if (result) {
    dynamicCopy = suggestedSetCopy.replace("[NAME]", result.name)
  }

  if (recommendation) {
    productTitle = recommendation.title
    productImages = recommendation.bundleImages
    productDetail = recommendation.bundleQuizResultsReco
    productPrice = recommendation.heroStartingPrice
    productLink = getSetLink(recommendation.slug)
  }

  useEffect(() => {
    setIsLoading(loading)
    if (!loading) {
      if (isBrowser()) {
        cvgTrack({
          eventName: 'Completed Quiz',
          profileProperties: {
            $email: result?.email
          },
          properties: {
            items: originalProducts.map((p) => parseProduct(p, p.variants[0])),
            recommendation: recommendation?.title,
          }
        })
        const dataLayer = window.dataLayer
        const eventId = id.quizCompleteEventId()
        if (typeof dataLayer !== "undefined") {
          dataLayer.push({
            event: "dl_quiz_complete",
            event_id: eventId,
            quiz: {
              complete: true,
            },
            ecommerce: {
              products: [
                {
                  name: recommendation.title,
                },
              ],
            },
          })
        }
      }
    }
  }, [loading])

  useEffect(() => {
    if (!isLoading) {
      setProducts(originalProducts)
    }
  }, [isLoading])

  let totalReviews = 0
  let ratingSum = 0
  const stars = []

  if (products) {
    products.forEach((product) => {
      totalReviews = totalReviews + (product?.review?.productReviews?.length || 0)
      product?.review?.productReviews?.forEach(({ review }) => {
        ratingSum += review.rating
      })
    })
  }

  const averageRating = (ratingSum / totalReviews).toFixed(1)

  for (let i = 0; i < averageRating; i++) {
    stars.push(<img src={Star} alt="Star" key={i} />)
  }
  const productHandle = productLink.replace("/sets/", "")

  return (
    <RecommendationContainer>
      <Title dangerouslySetInnerHTML={{ __html: dynamicCopy }} />
      {recommendation && (
        <React.Fragment>
          <RecommendedProduct>
            <a href={productLink}>
              <ProductImage
                image={getImage(productImages[0])}
                alt={productTitle || ""}
              />
            </a>
            <ProductTitle>
              <a href={productLink}>{productTitle}</a>
            </ProductTitle>
            <Rating>
              {stars} <span>{averageRating} </span>
              <span>({totalReviews})</span>
            </Rating>
            <ProductDetail
              dangerouslySetInnerHTML={{
                __html: productDetail.childMarkdownRemark.html,
              }}
            />
            <Price dangerouslySetInnerHTML={{ __html: productPrice }} />
            {products.length && (
              <WizardWrapper>
                <AddToCartWizard
                  products={products}
                  setProducts={setProducts}
                  setTitle={productTitle}
                  open={true}
                  handlePopup={setPopupOpen}
                  handle={productHandle}
                  category="Set"
                  currency="USD"
                  setPrice={productPrice}
                  isSet={true}
                  toggleVariable={false}
                  setToggleVariable={false}
                  atcHeaderStep1={atcHeaderStep1}
                  atcHeaderStep2={atcHeaderStep2}
                  oneTimeOptionSubcopy={atcOneTimePurchaseSubcopy}
                  subscriptionOptionSubcopy={atcAutoRenewSubcopy}
                  subscriptionOptionSubcopyStep2={atcAutoRenewSubcopyStep2}
                />
              </WizardWrapper>
            )}
            <LearnMore href={productLink}>LEARN MORE ABOUT THIS SET</LearnMore>
          </RecommendedProduct>
        </React.Fragment>
      )}
    </RecommendationContainer>
  )
}

export default Recommendation
